<template>
  <div>
    <div>
      <h3>User Management</h3>
      <p>In the search bar, you can search by a user's first name, last name, email, state, country, or any educational
        organization.</p>
      <p v-if="showResults">The user role dropdown will filter your results by what role a user has.</p>
      <p v-if="showResults">The filter bar on the table will filter results by specific text even further.</p>
    </div>
    <div class="row">
      <div class="col-sm-3" v-if="showResults">
        <label>Filter by Role</label>
        <select class="form-select" v-model="filterPositionId">
          <option :value="-1">User Type</option>
          <option :value="1" v-if="authUser.position.roleHierarchy === 1">ESU Administrator</option>
          <option :value="2" v-if="authUser.position.roleHierarchy <= 3">District Administrator</option>
          <option :value="3" v-if="authUser.position.roleHierarchy <= 4">Supervisor</option>
          <option :value="4">Para Educator</option>
          <option :value="5" v-if="authUser.position.roleHierarchy === 1">ESUCC/UNL</option>
          <option :value="6" v-if="authUser.position.roleHierarchy === 1">Nebraska Department of Education</option>
        </select>
      </div>

      <div :class="showResults ? `col-sm-3 offset-sm-6` : `col-sm-3 offset-9`">
        <div class="row">
          <form @submit.prevent="searchButtonClicked">
            <div style="text-align: left;">
              <label>Search</label>
              <input class="form-control" v-model="searchText" type="text" spellcheck="false" placeholder="Name, Email, Organization"/>
            </div>
            <div style="text-align: right;">
              <button type="submit" class="button btn-primary bi-search" @click="searchButtonClicked"> Search</button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showResults">
    <div class="col-sm-3" style="text-align: left;">
      <label>Filter</label>
      <input class="form-control" v-model="filterParam" type="text" placeholder="Filter by (name, email, organization)"/>
    </div>
    <div class="col-sm-3">
<!--      <b-pagination-->
<!--          v-model="pageNum"-->
<!--          :total-rows="totalPages"-->
<!--          :per-page="pageSize"-->
<!--          first-text="First"-->
<!--          last-text="Last"-->
<!--          prev-text="Prev"-->
<!--          next-text="Next"-->
<!--      ></b-pagination>-->
    </div>
    <UserTable :user-data="finalUserArray" :auth-token="authToken"
               :field-headers="fields"
               :search-param="filterParam"
               :viewing-paras="true"
    />
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from 'vue';
import axios from "axios";
import {useStore} from "vuex";
import {searchForUser, searchUserCount} from "@/Helpers/ApiCalls/UserAPICalls";
import UserTable from "@/views/User/UserTable";
import {isSuperUser} from "@/RoleCheck";
import Swal from "sweetalert2";

export default {
  name: "UserSearch",
  components: {UserTable},
  setup() {
    const searchText = ref("");
    const filterParam = ref("");
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const authUser = store.getters["auth/user"];
    const users = ref([]);
    const fields = ref({});
    const filterPositionId = ref(-1);
    const pageNum = ref(1);
    const pageSize = ref(50);
    const totalResultCount = ref(0);

    onBeforeMount(() => {
      if (!isSuperUser(authUser.position.roleHierarchy)) {
        // Double call mounts the table properly with data. Digging into why it's not populating the table properly otherwise.
        // This is a hacky fix that I'm working on solving, but it does create the on mount behavior we want.
        searchUser();
        searchUser();
      }
      if (localStorage.getItem('userFilterQuery')) {
        filterParam.value = localStorage.getItem('userFilterQuery');
      }

      if (localStorage.getItem('userSearchQuery')) {
        searchText.value = localStorage.getItem('userSearchQuery');
        searchUser();
      }
      setFields();
    })


    const showResults = computed(() => {
      return users.value.length > 0;
    })

    async function getTotalResultCount() {
      if ((!isSuperUser(authUser.position.roleHierarchy) || (searchText.value.length >= 2))) {
        await axios.get(searchUserCount(), {
          params: {
            keyword: searchText.value
          },
          headers: {
            "Authorization": "Bearer " + authToken.value
          }
        }).then((result) => {
          totalResultCount.value = result.data;
        })
      }
    }

    function searchButtonClicked() {
      pageNum.value = 0;
      searchUserCount();
      searchUser();
    }

    function pageSizeChanged(event) {
      pageSize.value = event;
      pageNum.value = 0;
      searchUser();
    }

    function pageNumChanged(event) {
      pageNum.value = event;
      searchUser();
    }


    async function searchUser() {
      if ((!isSuperUser(authUser.position.roleHierarchy) || (searchText.value.length >= 2))) {
        await axios.get(searchForUser(), {
          params: {
            keyword: searchText.value,
          },
          headers: {
            "Authorization": "Bearer " + authToken.value
          }
        }).then((result) => {
          users.value = setUserObjects(result.data);
        }).finally(() => {
          localStorage.setItem('userSearchQuery', searchText.value);
        })
      } else {
        Swal.fire({
          title: "Missing Search Query",
          text: "Your elevated privileges would return too many results with an empty search query.\nPlease include search criteria.",
          confirmButtonText: "Okay",
          icon: "error"
        })
      }
    }

    function setUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {
        let user = {
          name: userList[i]['firstName'] + " " + userList[i]['lastName'],
          email: userList[i]['email'],
          state: userList[i]['stateName'],
          country: userList[i]['countryName'],
          esu: userList[i]['esuName'] !== null ? userList[i]['esuName'] : "",
          district: userList[i]['districtName'] !== null ? userList[i]['districtName'] : "",
          school: userList[i]['schoolName'] !== null ? userList[i]['schoolName'] : "",
          accountStatus: userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false,
          registrationDate: userList[i]['registrationDate'],
          idUser: btoa(userList[i]['idUser']),
          position: userList[i]['positionName'],
          idPosition: userList[i]['idPosition'],
          educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
              ? userList[i]['outStateOrgName'] : "",
          canvasId: userList[i]['canvasId'],
          enrollmentType: userList[i]['idEnrollmentType']
        }
        userObjList.push(user);
      }
      return userObjList;
    }

    function setFields() {
      if (isSuperUser(authUser.position.roleHierarchy)) {
        fields.value = {
          name: "Name",
          email: "Email",
          esu: "ESU",
          district: "District",
          school: "School",
          educationalOrganization: "Outstate Organization",
          state: "State",
          country: "Country",
          registrationDate: "Registration Date",
          position: "Role",
          accountStatus: "Account Disabled/Enabled"
        }
      } else if (authUser.inNebraska) {
        fields.value = {
          name: "Name",
          email: "Email",
          esu: "ESU",
          district: "District",
          school: "School",
          state: "State",
          country: "Country",
          registrationDate: "Registration Date",
          position: "Role",
          accountStatus: "Account Disabled/Enabled"
        }
      } else {
        fields.value = {
          name: "Name",
          email: "Email",
          educationalOrganization: "Outstate Organization",
          state: "State",
          country: "Country",
          registrationDate: "Registration Date",
          position: "Role",
          accountStatus: "Account Disabled/Enabled"
        }
      }
    }

    const finalUserArray = computed(() => {
      if (filterPositionId.value !== -1) {
        return users.value.filter((u) => u.idPosition === filterPositionId.value);
      }
      return users.value;
    })

    const viewingParas = computed(() => {
      return filterPositionId.value === -1 || filterPositionId.value === 3 || filterPositionId.value === 4;
    })

    const totalPages = computed(() => {
      return (totalResultCount.value / pageSize.value) + (totalResultCount.value % pageSize.value);
    })

    return {
      searchText,
      users,
      showResults,
      fields,
      authToken,
      filterParam,
      filterPositionId,
      finalUserArray,
      authUser,
      viewingParas,
      pageSize,
      pageNum,
      totalResultCount,
      totalPages,
      searchButtonClicked,
      pageSizeChanged,
      pageNumChanged,
    }
  }
}
</script>

<style scoped>

</style>